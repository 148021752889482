import { useEffect } from 'react';

const UseScrollHandler = () => {
  useEffect(() => {
    const handleScroll = () => {
      // シンプルにセクション間のスクロールを処理
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return null;
};

export default UseScrollHandler;
