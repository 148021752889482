import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Box, useMediaQuery, IconButton, Drawer, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          <Typography
            variant="h6"
            style={{
              fontFamily: "'Roboto', sans-serif", // フォントをRobotoに設定
              color: 'white',
              fontWeight: 'bold',
              animation: 'slideIn 0.5s ease-out',
            }}
          >
            STELLA
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                sx={{ color: 'white' }}  // ハンバーガーメニューの色を黒く設定
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <List>
                  <ListItem button onClick={() => handleScrollToSection('home')}>Home</ListItem>
                  <ListItem button onClick={() => handleScrollToSection('projects')}>Projects</ListItem>
                  <ListItem button onClick={() => handleScrollToSection('team')}>Message</ListItem>
                  <ListItem button onClick={() => handleScrollToSection('contact')}>Contact</ListItem>
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <Button
                sx={{
                  color: 'white',
                  position: 'relative',
                  fontFamily: "'Roboto', sans-serif", // フォントをRobotoに設定
                  animation: 'slideIn 0.6s ease-out',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    transform: 'scaleX(0)',
                    height: '2px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'black',
                    transformOrigin: 'bottom right',
                    transition: 'transform 0.25s ease-out',
                  },
                  '&:hover:after': {
                    transform: 'scaleX(1)',
                    transformOrigin: 'bottom left',
                  },
                }}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                Home
              </Button>
              <Button
                sx={{
                  color: 'white',
                  position: 'relative',
                  fontFamily: "'Roboto', sans-serif", // フォントをRobotoに設定
                  animation: 'slideIn 0.7s ease-out',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    transform: 'scaleX(0)',
                    height: '2px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'black',
                    transformOrigin: 'bottom right',
                    transition: 'transform 0.25s ease-out',
                  },
                  '&:hover:after': {
                    transform: 'scaleX(1)',
                    transformOrigin: 'bottom left',
                  },
                }}
                onClick={() => handleScrollToSection('projects')}
              >
                Projects
              </Button>
              <Button
                sx={{
                  color: 'white',
                  position: 'relative',
                  fontFamily: "'Roboto', sans-serif", // フォントをRobotoに設定
                  animation: 'slideIn 0.8s ease-out',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    transform: 'scaleX(0)',
                    height: '2px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'black',
                    transformOrigin: 'bottom right',
                    transition: 'transform 0.25s ease-out',
                  },
                  '&:hover:after': {
                    transform: 'scaleX(1)',
                    transformOrigin: 'bottom left',
                  },
                }}
                onClick={() => handleScrollToSection('team')}
              >
                Message
              </Button>
              <Button
                sx={{
                  color: 'white',
                  position: 'relative',
                  fontFamily: "'Roboto', sans-serif", // フォントをRobotoに設定
                  animation: 'slideIn 0.9s ease-out',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    transform: 'scaleX(0)',
                    height: '2px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'black',
                    transformOrigin: 'bottom right',
                    transition: 'transform 0.25s ease-out',
                  },
                  '&:hover:after': {
                    transform: 'scaleX(1)',
                    transformOrigin: 'bottom left',
                  },
                }}
                onClick={() => handleScrollToSection('contact')}
              >
                Contact
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* これでコンテンツがナビバーに隠れないように調整 */}
    </>
  );
}

export default Navbar;
