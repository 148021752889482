import React, { useRef, useEffect } from 'react';

const GenerativeArt = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const waveLayers = [
      { amplitude: 20, wavelength: 200, speed: 0.005, phase: 0, direction: 1, color: 'rgba(255, 165, 0, 0.5)' },
      { amplitude: 15, wavelength: 300, speed: 0.004, phase: Math.PI, direction: -1, color: 'rgba(255, 69, 0, 0.5)' },
      { amplitude: 25, wavelength: 100, speed: 0.006, phase: Math.PI / 2, direction: 1, color: 'rgba(255, 20, 147, 0.5)' },
    ];

    let time = 0;

    const drawWave = (amplitude, wavelength, speed, phase, direction, color) => {
      ctx.beginPath();

      for (let x = 0; x < canvas.width; x++) {
        // 交差する螺旋状の動きを強調
        const y = Math.sin((x + time * speed * direction + phase) / wavelength) * amplitude + canvas.height / 2 + Math.sin((x / 100 + time * speed) * direction + phase) * 50;
        ctx.lineTo(x, y);
      }

      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.stroke();
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      waveLayers.forEach(layer => {
        drawWave(layer.amplitude, layer.wavelength, layer.speed, layer.phase, layer.direction, layer.color);
      });

      time += 0.5; // 時間の増加量を調整
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default GenerativeArt;
