import React, { useEffect, useRef } from 'react';
import { Container, Typography, Grid, Card } from '@mui/material';
import { styled } from '@mui/system';

// Images
import ucartImage from '../assets/images/project_ucart.png';
import fairsAdminImage from '../assets/images/project_Fairs_admin.png';
import fairsImage from '../assets/images/project_Fairs.png';
import synchroImage from '../assets/images/project_synchro.png';
import wuuImage from '../assets/images/project_wuu.png';

const ProjectsContainer = styled(Container)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '50px 0',
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 0',
  },
}));

const ProjectCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, height 0.3s ease-in-out',
  borderRadius: 10,
  boxShadow: 'none',
  minWidth: '400px',
  height: '600px',
  margin: '0 40px',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.4)',
  },
  '&:hover .project-overlay': {
    height: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '300px', // モバイルではカードの横幅を小さく
    height: '500px',
    margin: '0 20px',
  },
}));

const ProjectOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  color: 'white',
  padding: '10px',
  paddingLeft: '10%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(20px)',
  WebkitBackdropFilter: 'blur(20px)',
  transition: 'height 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    height: '25%', // モバイルでは高さを調整
  },
}));

const ProjectImage = styled('div')(({ background }) => ({
  width: '100%',
  height: '100%',
  background: `url(${background}) no-repeat center center / cover`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ProjectServiceName = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem', // モバイルではフォントサイズを少し縮小
  },
}));

const ProjectServiceDescription = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem', // モバイルではフォントサイズを少し縮小
  },
}));

const ProjectsTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '40px',
  fontFamily: "'Roboto', cursive",
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem', // モバイルではフォントサイズを縮小
    marginBottom: '20px', // マージンも調整
  },
}));

function ProjectsSection() {
  const projectImages = [
    ucartImage,
    fairsImage,
    fairsAdminImage,
    wuuImage,
    synchroImage,
  ];
  const projectNames = [
    'Ucart',
    'Fairs',
    'Fairs Admin',
    'Wuu',
    'Synchro',
  ];
  const projectDescriptions = [
    '韓国サイトの商品を簡単に買えるECアプリ',
    '中国激安雑貨のマーケットプレイス',
    '中国激安雑貨の管理画面サイト',
    '新感覚ゲーム通販アプリ',
    'YouTubeの同時視聴ができるSNSアプリ',
  ];

  const containerRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = (event) => {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const currentScrollLeft = container.scrollLeft;
      const deltaY = event.deltaY;

      if (currentScrollLeft >= maxScrollLeft && deltaY > 0) {
        // 最後のプロジェクトカードにいるとき、下方向の垂直スクロールを許可
        wrapperRef.current.style.overflowY = 'auto';
      } else if (currentScrollLeft <= 0 && deltaY < 0) {
        // 最初のプロジェクトカードにいるとき、上方向の垂直スクロールを許可
        wrapperRef.current.style.overflowY = 'auto';
      } else {
        // ProjectsSection内の水平スクロール
        wrapperRef.current.style.overflowY = 'hidden';
        container.scrollLeft += deltaY;
        event.preventDefault(); // ここでのpreventDefaultは水平スクロール時のみ
      }
    };

    container.addEventListener('wheel', handleScroll);

    return () => {
      container.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <div ref={wrapperRef} style={{ overflowY: 'hidden', height: '100vh' }}>
      <ProjectsContainer ref={containerRef} maxWidth={false} disableGutters>
        <ProjectsTitle align="left" style={{ paddingLeft: '5%' }}>Our Projects</ProjectsTitle>
        <Grid container spacing={2} justifyContent="flex-start" wrap="nowrap">
          {projectImages.map((image, index) => (
            <Grid
              item
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ProjectCard>
                <ProjectImage background={image} />
                <ProjectOverlay className="project-overlay">
                  <ProjectServiceName>{projectNames[index]}</ProjectServiceName>
                  <ProjectServiceDescription>{projectDescriptions[index]}</ProjectServiceDescription>
                </ProjectOverlay>
              </ProjectCard>
            </Grid>
          ))}
        </Grid>
      </ProjectsContainer>
    </div>
  );
}

export default ProjectsSection;
