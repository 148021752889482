import React from 'react';
import { Container, Typography, Grid, Box, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import ceoImage from '../assets/images/ceo.png';

const TeamContainer = styled(Container)(({ theme }) => ({
  padding: '20px',
  paddingLeft: '50px', // 左側に50pxのスペースを追加
  borderRadius: '8px',
  textAlign: 'center',
  width: '90%', // 全体の幅を90%に設定
  margin: '20px auto',
  color: '#fff', // テキストの色を白に設定
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '20px', // モバイルでは左のパディングを20pxに縮小
    width: '95%', // モバイルでは幅を95%に設定
  },
}));

const TeamTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem', // フォントサイズを調整
  fontWeight: 'bold',
  marginBottom: '20px',
  fontFamily: "'Roboto', sans-serif",
  textAlign: 'left', // 左寄せに設定
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem', // モバイルではフォントサイズを縮小
  },
}));

const TeamMemberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px', // 内側に20pxのスペースを追加
  borderRadius: '12px',
  background: 'rgba(0, 0, 0, 0.1)', // 背景色を黒に設定し透明度50%
  backdropFilter: 'blur(10px)', // blur効果を追加
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: '#fff', // テキストの色を白に設定
  textAlign: 'center',
  minHeight: '300px', // 最小高さを設定
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '10px', // モバイルでは内側のパディングを縮小
    minHeight: '250px', // モバイルでは最小高さを縮小
  },
}));

const MemberInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  textAlign: 'left',
  justifyContent: 'flex-start', // 左寄せに設定
  width: '100%',
  padding: '0 20px', // 左右に20pxのスペースを追加
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', // モバイルでは縦方向にレイアウト変更
    alignItems: 'center', // モバイルでは中央揃えに変更
    textAlign: 'center', // モバイルでは中央揃えに変更
    padding: '0 10px', // モバイルではパディングを縮小
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 90,
  height: 90,
  margin: '30px',
  [theme.breakpoints.down('sm')]: {
    width: 70, // モバイルではアバターサイズを縮小
    height: 70,
    margin: '10px', // モバイルではマージンを縮小
  },
}));

const MemberName = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem', // モバイルではフォントサイズを縮小
  },
}));

const MemberPosition = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#aaa', // 少し落ち着いた色
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem', // モバイルではフォントサイズを縮小
  },
}));

const MemberDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1.8rem', // フォントサイズを大きく設定
  marginTop: '50px',
  color: '#ddd', // 少し落ち着いた色
  fontStyle: 'italic', // ダブルクオーテーションを強調するために斜体に設定
  padding: '0 40px', // 左右に50pxのスペースを追加
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem', // モバイルではフォントサイズを縮小
    padding: '0 20px', // モバイルではパディングを縮小
    marginTop: '20px', // モバイルではマージンを縮小
  },
}));

function TeamSection() {
  const ceo = {
    name: 'Fukuyoshi Masayuki',
    position: '代表取締役',
    image: ceoImage,
    description: 'これまでEC、SNS、マーケットプレイスなどのたくさんのサイトやアプリを作ってきました。これからも沢山のお客様に便利に、楽しく、愛して使っていただけるサービス作りを追求していきます。',
  };

  return (
    <TeamContainer id="team" maxWidth={false} disableGutters>
      <TeamTitle>Message</TeamTitle>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <TeamMemberBox>
            <MemberDescription>“{ceo.description}”</MemberDescription>
            <Box height="90px" /> {/* 擬似要素の代わりにボックスを使用 */}
            <MemberInfo>
              <StyledAvatar src={ceo.image} alt={ceo.name} />
              <Box>
                <MemberName>{ceo.name}</MemberName>
                <MemberPosition>{ceo.position}</MemberPosition>
              </Box>
            </MemberInfo>
          </TeamMemberBox>
        </Grid>
      </Grid>
    </TeamContainer>
  );
}

export default TeamSection;
