import React from 'react';
import { Box, Typography, Button, TextField, Container } from '@mui/material';
import { styled } from '@mui/system';

const ContactContainer = styled(Container)(({ theme }) => ({
  background: 'linear-gradient(90deg, #ff8a00, #e52e71)',
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)',
  padding: '80px 20px',
  borderRadius: '10px',
  textAlign: 'center',
  width: '80%',
  margin: '0 auto',  // モバイルでも中央に配置されるように修正
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

const ContactTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 'bold',
  marginBottom: '20px',
  fontFamily: "'Roboto', sans-serif",
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const ContactText = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  marginBottom: '40px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    marginBottom: '30px',
  },
}));

const ContactForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    gap: '15px',
  },
}));

const ContactButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  textTransform: 'none',
  backgroundColor: '#fff',
  color: 'black',
  padding: '10px 20px',
  borderRadius: '120px',
  fontWeight: 500,
  alignSelf: 'center',
  width: '50%',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    fontSize: '0.9rem',
  },
}));

function Contact() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" width="100%">  {/* width="100%" を追加 */}
      <ContactContainer>
        <ContactTitle>案件・協業について</ContactTitle>
        <ContactText>
          私たちと一緒に、<br />お客様に愛されるプロダクト作りをしましょう!
        </ContactText>
        <button
          style={{
            fontFamily: "sans-serif",
            lineHeight: "16px",
            color: "rgb(51, 51, 51)",
            backgroundColor: "rgba(243, 244, 245, 0.77)",
            border: "none",
            borderRadius: "40px",
            padding: "20px 40px",
            margin: "20px 0",
            fontSize: "16px",
            cursor: "pointer",
            textDecoration: "none",
          }}
          onClick={() => window.location.href = "mailto:support@stellajp.net"}
        >
          <span
            style={{
              fontFamily: '"Josefin Sans"',
              color: "rgb(79, 78, 78)",
              fontSize: "14px",
              fontWeight: "400",
              letterSpacing: "1.05px",
              textAlign: "center",
            }}
          >
            support@stellajp.net
          </span>
        </button>
      </ContactContainer>
    </Box>
  );
}

export default Contact;
