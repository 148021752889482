import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Plane } from '@react-three/drei';
import { useSpring, animated, config } from '@react-spring/web';

const HeroContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'relative',
  textAlign: 'left',
  color: 'white',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
  boxSizing: 'border-box',
  paddingTop: '100px',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingTop: '60px',
  },
}));

const HeroText = styled(Typography)(({ theme }) => ({
  fontSize: '6rem',
  fontWeight: 900,
  fontFamily: "'Roboto', sans-serif",
  zIndex: 20,
  width: '100%',
  marginLeft: '10%',
  letterSpacing: '0.05em',
  lineHeight: '1.1',
  marginBottom: '20px',
  background: 'linear-gradient(90deg, #ff8a00, #e52e71)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    fontSize: '10rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.5rem',
    marginLeft: '10%',
  },
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: "'Roboto', sans-serif",
  marginTop: '10px',
  zIndex: 20,
  width: '100%',
  marginLeft: '13%',
  letterSpacing: '0.05em',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    marginLeft: '10%',
  },
}));

const Wave = () => {
  const meshRef = useRef();
  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    meshRef.current.position.z = Math.sin(t) * 0.5;
  });

  return (
    <Plane ref={meshRef} args={[10, 10, 32, 32]}>
      <meshStandardMaterial color="#1e90ff" wireframe />
    </Plane>
  );
};

const HeroSection = () => {
  const [showSubText, setShowSubText] = useState(false);

  const heroTextSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 400,
    config: config.molasses,
  });

  const subTextSpring = useSpring({
    opacity: showSubText ? 1 : 0,
    from: { opacity: 0 },
    delay: 600,
    config: config.molasses,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSubText(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <HeroContainer>
      <Canvas style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: -1 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <Wave />
      </Canvas>
      <animated.div style={heroTextSpring}>
        <HeroText>
          Craft <br />
          Loved <br />
          Products
        </HeroText>
      </animated.div>
      <animated.div style={subTextSpring}>
        <SubText>お客様に愛されるプロダクト作りを追求します。</SubText>
      </animated.div>
    </HeroContainer>
  );
};

export default HeroSection;
