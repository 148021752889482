import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css'; // グローバルスタイルのインポート
import CustomCursor from './components/CustomCursor';
import Navbar from './components/Header';
import HeroSection from './components/HeroSection';
import ProjectsSection from './components/ProjectsSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
// import SplashScreen from './components/SplashScreen';
import GenerativeArt from './components/GenerativeArt';
import TeamSection from './components/TeamSection';
// import PrototypeContainer from './components/PrototypeContainer';
import UseScrollHandler from './hooks/UseScrollHandler'; // カスタムフックのインポート

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoadingComplete = () => {
    setIsLoading(false);
  };

  UseScrollHandler(); // カスタムフックの使用

  return (
    <Router>
      <div style={{ position: 'relative', minHeight: '100vh' }}>
        <GenerativeArt style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }} />
        <div style={{ position: 'relative', zIndex: 1 }}>
          <Navbar />
          <div className="section">
            <HeroSection />
          </div>
          <div className="section" id="projects">
            <ProjectsSection />
          </div>
          {/* <div className="section">
            <PrototypeContainer />
          </div> */}
          <div className="section">
            <TeamSection />
          </div>
          <div className="section">
            <ContactForm />
          </div>
          <div className="section">
            <Footer />
          </div>
        </div>
        {/* <CustomCursor /> */}
      </div>
    </Router>
  );
}

export default App;
