import React from 'react';
import { Container, Grid, Typography, Box, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const FooterContainer = styled(Box)({
  color: '#fff',
  padding: '40px 0',
  marginTop: '100px',
  '&::after': { // 擬似要素を無効にするスタイルを追加
    content: 'none',
  },
});

const FooterContent = styled(Container)({
  maxWidth: '1200px',
  textAlign: 'left', // 'center' から 'left' に変更
  padding: '0 20px',
  margin: '0 auto', // コンテンツを中央に配置
});

const Logo = styled(Typography)({
  fontFamily: 'Arial, sans-serif',
  fontSize: '1.8rem',
  fontWeight: 'bold',
  letterSpacing: '0.1rem',
  marginBottom: '20px',
});

const FooterSection = styled(Box)({
  marginBottom: '20px',
});

const FooterLabel = styled(Typography)({
  fontSize: '1rem',
  color: '#fff',
  fontWeight: 'bold',
  marginBottom: '10px',
});

const FooterItem = styled(Typography)({
  fontSize: '0.9rem',
  color: '#ccc',
  marginBottom: '10px',
});

const CopyrightText = styled(Typography)({
  color: '#ccc',
  textAlign: 'center',
  fontSize: '0.8rem',
  position: 'absolute',
  bottom: '1%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
});

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <FooterContainer>
      <FooterContent>
        <Grid container spacing={4} justifyContent="space-between"> {/* justifyContent="center" から "space-between" に変更 */}
          <Grid item xs={12} md={3}> {/* xs={12} md={4} から xs={12} md={3} に変更 */}
            <FooterSection>
              <Logo>STELLA</Logo>
              <FooterItem>STELLA, Inc.</FooterItem>
              <FooterItem>〒150-0002 東京都渋谷区渋谷三丁目1番9号YAZAWAビルUCF3F</FooterItem>
            </FooterSection>
          </Grid>
          <Grid item xs={12} md={3}> {/* xs={12} md={4} から xs={12} md={3} に変更 */}
            <FooterSection>
              <FooterLabel>会社情報</FooterLabel>
              <FooterItem>設立：2019年1月30日</FooterItem>
              <FooterItem>資本金(準備金含む)：1.16億円</FooterItem>
              <FooterItem>代表：福吉 真之</FooterItem>
            </FooterSection>
          </Grid>
          <Grid item xs={12} md={3}> {/* xs={12} md={4} から xs={12} md={3} に変更 */}
            <FooterSection>
              <FooterLabel>リンク</FooterLabel>
              <FooterItem>
                <MuiLink
                  href="#herosection"
                  color="inherit"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollToSection('herosection');
                  }}
                >
                  Home
                </MuiLink>
              </FooterItem>
              <FooterItem>
                <MuiLink
                  href="#projects"
                  color="inherit"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollToSection('projects');
                  }}
                >
                  Projects
                </MuiLink>
              </FooterItem>
              <FooterItem>
                <MuiLink
                  href="#team"
                  color="inherit"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollToSection('team');
                  }}
                >
                  Message
                </MuiLink>
              </FooterItem>
              <FooterItem>
                <MuiLink
                  href="#contact"
                  color="inherit"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollToSection('contact');
                  }}
                >
                  Contact
                </MuiLink>
              </FooterItem>
            </FooterSection>
          </Grid>
        </Grid>
        <CopyrightText>
          All Rights Reserved. 2024 STELLA, Inc.
        </CopyrightText>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
